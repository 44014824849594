<template>
    <div class="container">
        <div v-show="$route.name == 'ImportAndExport'">
            <div class="perform_box">
                <div class="title">
                    <div class="title_txt flex_center_start_box">
                        <img src="../../../assets/img/detail/title_ic5.png" />
                        Import & Export
                    </div>
                </div>

                <div class="footer" v-if="code">
                    <div class="ecahrts-header">
                        <span class="title-left title_txt">
                            <img src="../../../assets/img/detail/title_ic5.png" alt="" style="margin-right: 8px" />
                            Import & Export
                        </span>

                        <div class="head_loging">
                            <el-switch active-color="#13ce66" v-model="loginStatus"></el-switch>
                        </div>
                    </div>
                    <div v-show="loginStatus" class="ecahrtstu">
                        <div class="table">
                            <div class="table_box" style="position: relative">
                                <div class="import_export_box">
                                    <div class="i_e_group">
                                        <div class="i_e_item">
                                            <div class="title">Top Product Terms</div>
                                            <div class="tip">by number of shipments</div>
                                            <div class="line" v-for="(item, index) in topProductTerms" :key="index">
                                                <span class="step">{{ index + 1 }}</span>
                                                <span class="txt">{{ item.nameEn }}</span>
                                            </div>
                                        </div>
                                        <div class="i_e_item">
                                            <div class="title">Top Customers</div>
                                            <div class="tip">by number of shipments</div>
                                            <div class="line_step" v-for="(item, index) in topCustomers" :key="index">
                                                <div class="txt flex_center_between_box">
                                                    <span class="line_title">{{ item.nameEn }}</span>
                                                    <span class="blue_num">{{ item.number | FilterNum }}</span>
                                                </div>
                                                <el-progress color="#1290C9" :show-text="false" :percentage="getProgress(item)"></el-progress>
                                            </div>
                                        </div>
                                        <div class="i_e_item">
                                            <div class="title">Top Suppliers</div>
                                            <div class="tip">by number of shipments</div>
                                            <div class="line_step" v-for="(item, index) in topSuppliers" :key="index">
                                                <div class="txt flex_center_between_box">
                                                    <span class="line_title">{{ item.nameEn }}</span>
                                                    <span class="gree_num">{{ item.number | FilterNum }}</span>
                                                </div>
                                                <el-progress color="#55B419" :show-text="false" :percentage="getProgress(item)"></el-progress>
                                            </div>
                                        </div>
                                        <div class="i_e_item">
                                            <div class="title">Top Countries</div>
                                            <div class="tip">by number of shipments</div>
                                            <div class="line_step" v-for="(item, index) in topCountries" :key="index">
                                                <div class="txt flex_center_between_box">
                                                    <span class="line_title">{{ item.nameEn }}</span>
                                                    <span class="blue_num">{{ item.number | FilterNum }}</span>
                                                </div>
                                                <el-progress color="#1290C9" :show-text="false" :percentage="getProgress(item)"></el-progress>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <button @click="update()" class="btn_blue">High quality information</button> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="computer_right_cont_box" v-if="parentTablelist?.length > 0">
                <div class="title" style="margin-top: 20px; margin-bottom: 0">
                    <span class="title-left title_txt">
                        <img src="../../../assets/img/detail/title_ic5.png" style="margin-right: 8px" />
                        Related Import & Export
                    </span>
                </div>
                <div class="infinite-list-wrapper" v-infinite-scroll="infiniteScroll" style="overflow: auto" :infinite-scroll-disabled="false" :infinite-scroll-distance="5">
                    <!-- <div v-infinite-scroll="load" infinite-scroll-disabled="disabled"> -->
                    <div class="footer" v-for="(item, index) in parentTablelist" :key="index" v-loading="item.loading2">
                        <div class="ecahrts-header">
                            <span class="title-left title_txt">
                                <img src="../../../assets/img/detail/title_ic5.png" alt="" style="margin-right: 8px" />
                                <span v-show="!showTranslate" style="color: #1290c9; cursor: pointer; margin-right: 5px; width: 580px" class="line_1" @click="setid(item)">{{ item.nameEn }}</span>
                                <span v-show="showTranslate" style="color: #1290c9; cursor: pointer; margin-right: 5px; width: 580px" class="line_1" @click="setid(item)">{{ item.nameCn }}</span>
                                Import & Export
                            </span>
                            <span style="display: flex; justify-content: flex-start; width: 280px">relations:{{ item.typeEn }}</span>

                            <div class="head_loging">
                                <el-switch active-color="#13ce66" :value="loginStatus1[index]" @change="loginPop(index, item.aaaid, item.nameEn)"></el-switch>
                            </div>
                        </div>
                        <div v-show="loginStatus1[index]" class="ecahrtstu">
                            <div class="table">
                                <div class="table_box" style="position: relative">
                                    <div class="import_export_box">
                                        <div class="i_e_group">
                                            <div class="i_e_item">
                                                <div class="title">Top Product Terms</div>
                                                <div class="tip">by number of shipments</div>
                                                <div class="line" v-for="(ite, inde) in item.topProductTerms" :key="inde">
                                                    <span class="step">{{ inde + 1 }}</span>
                                                    <span class="txt">{{ ite.nameEn }}</span>
                                                </div>
                                            </div>
                                            <div class="i_e_item">
                                                <div class="title">Top Customers</div>
                                                <div class="tip">by number of shipments</div>
                                                <div class="line_step" v-for="(ite, inde) in item.topCustomers" :key="inde">
                                                    <div class="txt flex_center_between_box">
                                                        <span class="line_title">{{ ite.nameEn }}</span>
                                                        <span class="blue_num">{{ ite.number | FilterNum }}</span>
                                                    </div>
                                                    <el-progress color="#1290C9" :show-text="false" :percentage="getProgress(ite)"></el-progress>
                                                </div>
                                            </div>
                                            <div class="i_e_item">
                                                <div class="title">Top Suppliers</div>
                                                <div class="tip">by number of shipments</div>
                                                <div class="line_step" v-for="(ite, inde) in item.topSuppliers" :key="inde">
                                                    <div class="txt flex_center_between_box">
                                                        <span class="line_title">{{ ite.nameEn }}</span>
                                                        <span class="gree_num">{{ ite.number | FilterNum }}</span>
                                                    </div>
                                                    <el-progress color="#55B419" :show-text="false" :percentage="getProgress(ite)"></el-progress>
                                                </div>
                                            </div>
                                            <div class="i_e_item">
                                                <div class="title">Top Countries</div>
                                                <div class="tip">by number of shipments</div>
                                                <div class="line_step" v-for="(ite, inde) in item.topCountries" :key="inde">
                                                    <div class="txt flex_center_between_box">
                                                        <span class="line_title">{{ ite.nameEn }}</span>
                                                        <span class="blue_num">{{ ite.number | FilterNum }}</span>
                                                    </div>
                                                    <el-progress color="#1290C9" :show-text="false" :percentage="getProgress(ite)"></el-progress>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <button @click="update()" class="btn_blue">High quality information</button> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="back-to-top" v-show="showBackToTop" @click="scrollToTop">
                <button class="el-icon-top gaodu"></button>
            </div>
            <el-dialog :visible.sync="newDialog" center :close-on-click-modal="false" @close="closeDialog" custom-class="dialog_normalsuo" :append-to-body="true">
                <div>
                    <!-- <div class="dialog_detail_title">Pay attention to</div> -->
                    <div class="detail_cont">
                        <div class="detail_cont">
                            <div class="detail_cont_text">• Import & export information is a paid feature, this will cost 2 units.</div>
                        </div>

                        <!-- <div>• Shareholder penetration is a paid feature, this will cost 2 units.</div> -->
                    </div>
                </div>
                <div class="flex_center_between_box">
                    <el-button class="btn_export" id="ok" @click="onSubmitkoudian()">OK</el-button>
                    <el-button class="btn_export" @click="onSubmitquxiao()">Buy credits</el-button>
                </div>
            </el-dialog>
        </div>
        <router-view></router-view>
    </div>
</template>
<script>
import { getImportExport, getrelatedCompanylist, getCompanyDetailMenuList, ordersAdd } from '@/api/companyApi.js';
import crypto from '@/utils/crypto';
import { mixins1 } from '@/mixins/index';
export default {
    mixins: [mixins1],
    data() {
        return {
            showBackToTop: false,
            obj: {},
            topCountries: [],
            parentTablelist: [],
            code: true,
            topCustomers: [],
            topProductTerms: [],
            topSuppliers: [],
            loginStatus: true,
            loginStatus1: [],
            page: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },

            companyList: [],
            newDialog: false,
            indexclose: '',
            Obj: {
                id: '',
                name: '',
                index: '',
            },
            params: {},
            params1: {},
        };
    },
    computed: {
        //index传值 是否翻译
        showTranslate: {
            get() {
                return this.$parent.indexIsTranslate;
            },
            set() {},
        },

        curPagePower1: {
            get() {
                return this.$parent.powerToPage;
            },
            set() {},
        },
    },
    watch: {
        $route: {
            handler(newVal, oldVal) {
           
                if (newVal.name == 'ImportAndExport') {
                    this.getData();
                    this.getrelateList();
                }
                //
            },
            immediate: true,
        },
    },
    created() {
        // this.getData();
        // this.getrelateList();
    },
    mounted() {
        // window.addEventListener('resize', this.riskEchart)

        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        // 移除页面滚动事件的监听
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        closeDialog() {
            const okButton = document.getElementById('ok');

            okButton.removeEventListener('click', this.onSubmitkoudian);
            this.loginStatus1[this.indexclose] = false;
            this.parentTablelist[this.indexclose].loading2 = false;
            this.$set(this.parentTablelist, this.indexclose, this.parentTablelist[this.indexclose]);
            this.newDialog = false;
        },
        // 提示扣点
        onSubmitkoudian() {
            let that = this;
            let id = this.Obj.id;
            let nameEn = this.Obj.name;
            this.getOrdersAdd(13, id, nameEn).then(res => {
                if (res) {
                    let params = this.params;
                    getImportExport(params).then(res => {
                        let data = JSON.parse(crypto.decrypt(res));

                        if (data.code === true) {
                            let index = that.Obj.index;
                            that.parentTablelist[index].loading2 = false;

                            that.parentTablelist[index].topCountries = data.data.topCountries;
                            that.parentTablelist[index].topCustomers = data.data.topCustomers;
                            that.parentTablelist[index].topProductTerms = data.data.topProductTerms;
                            that.parentTablelist[index].topSuppliers = data.data.topSuppliers;
                            // this.parentTablelist[index].cardlist2 = this.list;

                            that.loginStatus1[index] = true;
                            that.$set(that.parentTablelist, index, that.parentTablelist[index]);
                        } else {
                            let index = that.Obj.index;
                            that.parentTablelist[index].loading2 = false;
                            that.$set(that.parentTablelist, index, that.parentTablelist[index]);
                        }
                    });
                } else {
                    let index = that.Obj.index;
               
                    this.loginStatus1[index] = false;
                    this.parentTablelist[index].loading2 = false;
                    this.$set(this.parentTablelist, index, this.parentTablelist[index]);
                }
            });
            this.newDialog = false;
        },

        // 取消
        onSubmitquxiao() {
            // this.newDialog = false;
            // this.$emit('closeCloseDialog', false);
            let that = this;
            let userinfo = JSON.parse(sessionStorage.getItem('userInfo')).userInfo;

          
            let routeData = '';
            setTimeout(function () {
                if (userinfo.type == '3') {
                    routeData = that.$router.resolve({
                        path: '/account/quota',
                    });
                } else {
                    routeData = that.$router.resolve({
                        path: '/account/addCredit',
                    });
                }

                window.open(routeData.href, '_blank');
            }, 1000);
        },
        handleScroll() {
            // 判断页面滚动距离是否超过200px，更新showBackToTop的值
            // console.log(` this.showBackToTop`, this.showBackToTop );
            this.showBackToTop = window.pageYOffset > 1000;
        },
        scrollToTop() {
            // 使用scrollTo()方法将页面滚动到顶部
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        },
        getProgress(item) {
            try {
                let tempData = (Number(item.number) / Number(item.numberTotal)) * 100;
                if (tempData > 0 && tempData <= 100) {
                    return tempData;
                } else {
                    return 0;
                }
            } catch (error) {
            
                return 0;
            }
        },
        getData() {
            const id3a = this.$route.query.id3a;
            const companyCountry = this.$route.query.companyCountry;
            const base = JSON.parse(sessionStorage.getItem('base'));
          
            let params = 'aaaId=' + id3a + '&countryCode=' + companyCountry + '&companyName=';

            getImportExport(params).then(res => {
                let data = JSON.parse(crypto.decrypt(res));
                console.log('result- ImportExport', data);
                try {
                    if (data && data.code) {
                     
                        if (Object.keys(data.data).length === 0) {
                            this.code = false;
                        }
                        this.topCountries = data.data.topCountries;
                        this.topCustomers = data.data.topCustomers;
                        this.topProductTerms = data.data.topProductTerms;
                        this.topSuppliers = data.data.topSuppliers;
                    } else {
                        this.code = false;
                    }
                } catch (error) {
                    console.error('topSuppliers', error);
                }
            });
        },
        infiniteScroll() {
            this.routeLoad = false;
            // this.page.pageNo += 1; // 页码每次滚动+1
            // this.getrelateList();
        },
        getrelateList() {
            getrelatedCompanylist({ id3a: this.$route.query.id3a, page: this.page.pageNo, pageSize: this.page.pageSize, resourceType: 2 }).then(res => {
                // console.log(res);
                if (res) {
                    let list = res.data.rows;
                    res.data.rows == null ? (res.data.rows = []) : res.data.rows;
                    // console.log(this.parentTablelist);
                    this.parentTablelist = res.data.rows;
                    this.routeLoad = false;
                    // this.parentTablelist = res.data.rows;

                    this.parentTablelist.map(item => {
                        item.loading2 = false;
                        item.topCountries = [];
                        item.topCustomers = [];
                        item.topProductTerms = [];
                        item.topSuppliers = [];

                        // item.loading1=fal
                    });
                }
            });
        },
        async loginPop(index, id, nameEn) {
            this.indexclose = index;
            this.$set(this.loginStatus1, index, !this.loginStatus1[index]);

            if (this.loginStatus1[index]) {
                this.parentTablelist[index].loading2 = true;
                // console.log(` this.parentTablelist[index]`, this.parentTablelist[index].loading2);
                let params = 'aaaId=' + id + '&companyName=';

                let res = await getCompanyDetailMenuList('code=' + id + '&type=' + 7);
                let power = res.data[6].power;
                if (power) {
                    getImportExport(params).then(res => {
                        let data = JSON.parse(crypto.decrypt(res));
                        // console.log(`data`, data);
                        if (data.code === true) {
                            this.parentTablelist[index].loading2 = false;
                            this.parentTablelist[index].topCountries = data.data.topCountries;
                            this.parentTablelist[index].topCustomers = data.data.topCustomers;
                            this.parentTablelist[index].topProductTerms = data.data.topProductTerms;
                            this.parentTablelist[index].topSuppliers = data.data.topSuppliers;
                            // this.parentTablelist[index].cardlist2 = this.list;
                            this.$set(this.parentTablelist, index, this.parentTablelist[index]);
                        } else {
                            // Message({
                            //   message: '账户或密码错误！',
                            //   type: 'warning'
                            // })
                            // return Promise.reject('error')
                        }
                    });
                } else {
                    this.newDialog = true;
                    this.params = params;
                    this.Obj.name = nameEn;
                    this.Obj.id = id;
                    this.Obj.index = index;
                    // this.getOrdersAdd(13, id, nameEn).then(res => {
                    //     if (res) {
                    //         getImportExport(params).then(res => {
                    //             let data = JSON.parse(crypto.decrypt(res));

                    //             if (data.code === true) {
                    //                 this.parentTablelist[index].loading2 = false;

                    //                 this.parentTablelist[index].topCountries = data.data.topCountries;
                    //                 this.parentTablelist[index].topCustomers = data.data.topCustomers;
                    //                 this.parentTablelist[index].topProductTerms = data.data.topProductTerms;
                    //                 this.parentTablelist[index].topSuppliers = data.data.topSuppliers;
                    //                 // this.parentTablelist[index].cardlist2 = this.list;

                    //                 this.$set(this.parentTablelist, index, this.parentTablelist[index]);
                    //                 // this.parentTablelist[index].cardlist2 = this.list;
                    //                 // commit('setcardlist', list);

                    //                 // resolve(data.data)
                    //             } else {
                    //                 this.parentTablelist[index].loading2 = false;
                    //                 // Message({
                    //                 //   message: '账户或密码错误！',
                    //                 //   type: 'warning'
                    //                 // })
                    //                 // return Promise.reject('error')
                    //             }
                    //         });
                    //     } else {
                    //         console.log(`111`, 111);
                    //         this.loginStatus1[index] = false;
                    //         this.parentTablelist[index].loading2 = false;
                    //         this.$set(this.parentTablelist, index, this.parentTablelist[index]);
                    //     }
                    // });
                }
            }
        },
        async getOrdersAdd(type, aid, nameEn) {
            const id3a = aid;

            //1  :线上报告
            let params = {
                companyName: nameEn, //必须
                aaaId: id3a, //必须
                // companyCountry: companyCountry, //必须
                method: '0', //支付方式  0：帐号余额 1：支付宝 2：微信 ，3 国际万事达卡 支付 传  0   //必须
                methodType: '0', //支付类型： 1 免费额度 ，0 充值额度       //(非必须)
                type: type, //订单类型 //必须
            };
            let status = '';

            await ordersAdd(params).then(result => {
                let data = result;
                if (data && data.code) {
                    //  getCompanyDetailMenuList('code=' + aid + '&type=' + 7).then(res=>{
                    //  console.log(res);
                    //  });
                    status = true;
                    if (data.msg != 'Saved successfully') {
                        this.$message({
                            message: data.msg,
                            type: 'success',
                            // duration: 5000,
                        });
                    }
                } else {
                    this.$message({
                        message: data.msg,
                        type: 'error',
                        // duration: 5000,
                    });
                    let that = this;
                    let userinfo = JSON.parse(sessionStorage.getItem('userInfo')).userInfo;

                    let routeData = '';
                    setTimeout(function () {
                        if (userinfo.type == '3') {
                            routeData = that.$router.resolve({
                                path: '/account/quota',
                            });
                        } else {
                            routeData = that.$router.resolve({
                                path: '/account/addCredit',
                            });
                        }

                        window.open(routeData.href, '_blank');
                    }, 2000);

                    status = false;
                }
            });
            this.getliulantime(params, 'ImportAndExport');
            return status;
        },
        setid(item) {
            console.log(item);
            // sessionStorage.setItem('company', JSON.stringify(row));
            let routeData = this.$router.resolve({
                path: '/company/companysnapshot?id3a=' + item.aaaid + '&companyCountry=' + '',
            });

            window.open(routeData.href, '_blank');
        },
    },
};
</script>
<style scoped lang="less">
.cont_m {
    margin-top: 30px;
    margin-bottom: 80px;
}
.perform_box {
    margin-top: 60px;
}
.title {
    margin-bottom: 30px;
}
.title_txt {
    font-size: 16px;
    font-family: 'Arial Bold';
}
.title_txt img {
    width: 20px;
    margin-right: 8px;
}
.i_e_group {
    display: flex;
    justify-content: space-between;
}
.i_e_item {
    width: 275px;
    padding: 14px;
    background: #ffffff;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    border-radius: 8px;
}
.i_e_item .title {
    font-size: 14px;
    color: #022955;
    font-family: 'Arial Bold';
    margin-bottom: 6px;
}
.i_e_item .tip {
    font-size: 12px;
    color: #8497ab;
    margin-bottom: 14px;
}
.i_e_item .line_step,
.i_e_item .line {
    font-size: 12px;
    color: #022955;
    margin-bottom: 24px;
}
.i_e_item .line_step {
    margin-bottom: 14px;
}
.i_e_item .line .step {
    display: inline-block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    font-size: 12px;
    color: #ffffff;
    text-align: center;
    margin-right: 6px;
    background: #1290c9;
    border-radius: 50%;
}
.i_e_item .line_step .line_title {
    display: inline-block;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.gree_num {
    display: block;
    color: #55b419;
    text-align: right;
}
.blue_num {
    display: block;
    text-align: right;
    color: #1290c9;
}
.i_e_item .line_step /deep/ .el-progress {
    margin-top: 4px;
}

.title {
    display: flex;
    align-items: center;

    justify-content: space-between;
    // margin-top: 69px;

    .title-left {
        display: flex;
        font-size: 16px;
        font-family: Arial-BoldMT, Arial;
        font-weight: normal;
        color: #022955;
    }
}

.table {
    margin-top: 20px;
}
.footer {
    margin-top: 20px;
    padding: 20px;
    overflow: hidden;
    background: #ffffff;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    border-radius: 14px;
}

.ecahrts-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title-left {
        display: flex;
    }
}
.infinite-list-wrapper {
    padding: 4px;
}
.table-biao {
    width: 100% !important;
}
.footer {
    margin-top: 20px;
    padding: 20px;

    overflow: hidden;
    background: #ffffff;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    border-radius: 14px;
}

.ecahrts-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title-left {
        display: flex;
    }
}
.back-to-top {
    z-index: 999;
    position: fixed;
    transition: opacity 0.4s ease-in-out 0s;
    opacity: 1;
    box-sizing: border-box;
    bottom: 86px;
    right: 45px;
    cursor: pointer;
    /* background-color: #1290c9; */
}

.gaodu {
    height: 40px;
    width: 40px;
    border-radius: 6px;
    background-color: #1290c9;
    color: #fff;
    font-size: 20px;
}
.line_1 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.detail_cont {
    padding: 0px 10px 10px;
}
.detail_cont_text {
    font-size: 14px;
    color: #022955;
}
@media (max-width: 821px){
.i_e_group{
    flex-wrap: wrap;
}
.i_e_item{
    width: 100% !important;
    margin-top: 20px ;
}
.footer {
    padding: 10px !important;
}
}

</style>
